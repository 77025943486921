<template>
  <Wishlist/>
</template>

<script>
import Wishlist from './components/Wishlist.vue'

export default {
  name: 'App',
  components: {
    Wishlist
  }
}
</script>
