import {createApp} from "vue";
import App from "./App.vue";
import currency from "@/filters/currency";

const app = createApp(App);

app.config.globalProperties.$filters = {
    currency
};

app.mount("#app");