<template lang="pug">
div
  .header
    span
    span Название
    span Где имеется
    span Примерная цена
  .items
    .item(
      v-for="(wishlistItem, i) in wishlistItems"
      :key="i"
      :class="{gifted: wishlistItem.gifted}"
    )
      label(
        v-if="wishlistItem.gifted"
        :title="getItemGiversName(wishlistItem)"
        @click="showItemGivers(wishlistItem)"
      ) Подарено!
      img(
        :src="wishlistItem.img_uri"
        :alt="wishlistItem.title"
      )
      p {{ wishlistItem.title }}
      p
        a(
          v-if="!!wishlistItem.uri"
          :href="wishlistItem.uri"
        )
          span Ссылка
        span(v-else) ?
      p {{ $filters.currency(wishlistItem.price) }}
</template>

<script>
import axios from "axios";

export default {
  name: "Wishlist",
  data: () => ({
    apiHost: process.env.VUE_APP_BACKEND_HOST,
    apiUri: "",
    wishlistItems: [],
  }),

  created() {
    this.apiUri = `${this.apiHost}/api/v1/wishlist`;
    this.fetchWishlist();
  },

  methods: {
    async fetchWishlist() {
      const a = axios.create();

      try {

        const response = await a.get(
            this.apiUri,
            {
              headers: {
                "Accept-Type": "application/json",
              }
            }
        );

        this.wishlistItems = [...response.data];

      } catch (e) {
        console.error(e);
      }
    },
    /**
     *
     * @param {WishlistItem} item
     * @return {string}
     */
    getItemGiversName(item) {
      if (!item.gifted_by) {
        return "";
      }

      const names = item.gifted_by.map(i => i.full_name);

      return names.join(", ");
    },

    /**
     *
     * @param {WishlistItem} item
     * @return {void}
     */
    showItemGivers(item) {
      if (!item.gifted_by || item.gifted_by.length === 0) {
        return;
      }

      const itemGiversName = this.getItemGiversName(item);

      alert(itemGiversName);
    }
  }
};
</script>
