export default function(value, fractionDigits = 0, currencyCode = null) {
    const locale = document.documentElement.lang === "ru" ? "ru-RU" : "en-US";

    let currency = "";

    if (!currencyCode) {
        // Имя локали в формате BCP 47

        // Код валюты в ISO 4217
        currency = locale === "ru-RU" ? "RUB" : "USD";
    } else {
        currency = currencyCode;
    }
    const options = {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionDigits,
    };

    return new Intl.NumberFormat(locale, options).format(value);
}